@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Ubuntu+Condensed&display=swap');

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.etiquetaCont{
  margin-top: -2px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.etiqueta{
  width: 40%;
  max-width: 300px;
}

.loadingCont{
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}