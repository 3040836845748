.mainContainer{
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subContainer{
  width: 100%;
  max-width: 1000px;
  background-color: white;
}
.infoCont{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.heroImg{
  width: 100%;
  padding-top: 20px;
}
.objName{
  font-size: 40px;
  font-family: 'Ubuntu Condensed', sans-serif;
}
.objBy{
  font-size: 15px;
  color: #777777;


}
/* .objDesc{
  font-size: 20px;
  font-family: 'Ubuntu Condensed', sans-serif;
} */
.objNav{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 10px;
  background-color: #55555555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}